import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {  fetchApi_TVS, resetLoggedUserDetail, setLoggedUserDetail } from "../../RTK/Login/userProfile";
import { Button } from 'primereact/button';
import '../../Styles/login.scss'
import { fetchUserList } from '../../RTK/Background/userProfileList';
import { fetchSiteList } from '../../RTK/Background/siteList';
import { useSelector } from 'react-redux';
import { resetOverallPendingData, resetPPFData } from '../../RTK/Background/pendingData';
import Swal from 'sweetalert2';


const TVSAWSLogin = (props) => {
  const email = useRef();
  const password = useRef();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const select = useSelector((state) => state.user.userdetail);

  const [isLoading, setIsLoading] = useState(false)
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    if (code) {
      fetch(`https://${process.env.REACT_APP_TVS_AWS_COGNITO_DOMAIN}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_TVS_AWS_EXTERNAL_CLIENT_ID}&code=${code}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_TVS_AWS_PROD_USER_APP_REDIRECT_URL)}`
      })
        .then(response => response.json())
        .then(data => {
          console.log(data)
          const access_token = data.access_token;
          const id_token = data.id_token;
          localStorage.setItem('token', access_token);
          localStorage.setItem('id_token', id_token);
          dispatch(fetchApi_TVS({token: data.access_token, id_token: data.id_token}));

        })
        .catch(error => console.error('Error:', error));
    }


  }, [])
  useEffect(() => {
    if (Object.keys(select).length !== 0) {
        if (select.role === "clientuser" || select.role === "clientsupplier") {

            if (select.information.blocked === undefined || !select.information.blocked) {
                dispatch(fetchUserList(select.clientId));
                dispatch(fetchSiteList(select.clientId))
                console.log(from)
                if (from.pathname === '/') {
                    if (select.role === "clientsupplier") {
                        props.history.push("/client_supplier/homescreen");
                    } else {
                        props.history.push("client_user_new/homescreen");
                    }
                } else {
                    history.push(from)
                }

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Blocked",
                    text: "You have been blocked from platform, contact admin for futher details",
                    returnInputValueOnDeny: () => {
                        console.log("deny");
                    },
                });
                // localStorage.clear();
                // dispatch(resetLoggedUserDetail());
            }
        }else if(select.role === "clientadmin" ){
            dispatch(fetchUserList(select.id));
            dispatch(fetchSiteList(select.id))
            props.history.push("client_user_new/homescreen");
        }
    }
}, [select]);
useEffect(() => {
    // console.log(DateTime.fromISO("2023-12-09T10:35:37.007Z",{zone:'utc'}).toLocal().toFormat('yyyy LLL dd HH mm'))
    localStorage.removeItem('token')
    dispatch(resetPPFData([]));
    dispatch(resetOverallPendingData([]));
    dispatch(resetLoggedUserDetail());

}, []);
  // const handleLogin = (e) => {
  //   e.preventDefault();
  //   const rEmail = email.current.value;
  //   const rPassword = password.current.value;
  //   Auth.signIn(rEmail, rPassword).then(async (result) => {
  //     //Success 
  //     console.log(result)
  //     const token = await getToken();
  //     localStorage.setItem('access_token', token);
  //     dispatch(loginActions.setLogin());
  //     history.push('/dashboard')

  //   }).catch((err) => {
  //     // Something is Wrong
  //   })

  // }

  const handleLoginWithAzure = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // const newUser = await Auth.federatedSignIn({ provider: 'ADWITHAWS' });
    // console.log(newUser);
    const redirectUrl = `https://${process.env.REACT_APP_TVS_AWS_COGNITO_DOMAIN}/oauth2/authorize?client_id=${process.env.REACT_APP_TVS_AWS_EXTERNAL_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(process.env.REACT_APP_TVS_AWS_PROD_USER_APP_REDIRECT_URL)}`;
    console.log(redirectUrl)
    window.location.replace(redirectUrl);
    setIsLoading(false);

  }




  return (
    <div className='col-12 font-lato p-0 flex justify-content-center align-items-center bg-white'  style={{marginTop:-10,width:'100vw',height:'100vh',position:'relative'}}>
    <div style={{position:'absolute',top:20,right:20}}>
    <div style={{width:180}}>
               <img src={require("../../assets/images/eisqr_logo_final_v1.png").default} alt="Login" className="w-full" />
           </div>
       </div>
       <div className="col-6 p-0">      <div >
           <img src={require("../../assets/tvs/tvs_login_user.jpg").default} alt="Login"  style={{height: 'calc(100vh - 4px)'}} className="w-full"/>
       </div></div>
   
       <div className="col-6 p-0  flex ">

     
       <div className="form-container  ml-7" style={{width:'80%'}} >
       <div style={{width:150}}>
           <img src={require("../../assets/tvs/tvs_logo.png").default} alt="Login"   className="w-full"/>
       </div>
       <h1 className="fs-22 fw-5 mt-2 mb-2" >Welcome to Environment, Social and Governance </h1>

  
       <div style={{width:'50%'}} >
  
       <Button rounded label={process.env.REACT_APP_TVS_APP_USER_LOGIN_BUTTON_TEXT} onClick={(e) => handleLoginWithAzure(e)} type="button" className={isLoading ? "btn btn-block btn-secondary  disabled" : "btn btn-block btn-secondary "}  >
                   
                  </Button>
      
       </div>
       </div>
       </div>


</div>
   
  )

}

export default TVSAWSLogin
