import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";

import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ1B, BP7EQ2, BP7LQ1, BP8EQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ7, SGXGSQ6, SGXGSQ4 } from "../../components/hardcoded/hardcodedRF";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
window.jQuery = $;
window.$ = $;

const RFInputEntrySubmission = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const client_info = useSelector((state) => state.userlist.admindetail)
    const { id } = useParams();
    const params = useLocation()
    // const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140','148','149','150','151','181','182','183']
    const [show, setShow] = useState(true)
    useEffect(async () => {
        console.log(params.state)
        APIServices.get(API.RF_Edit(id)).then((res) => {
            if (params.state.oldData.length === 0) {
                res.data.reject = 0
                setData(res.data)
            } else {
                setShow(params.state.oldData.type === 0 ? true : false)
                if (params.state.oldData.type === 0) {
                    if (hardcoded.rf.includes(id)) {
                        res.data.data1 = params.state.oldData.response
                    } else {
                        res.data.data1 = migrateResponse(res.data.data1, params.state.oldData.response)
                    }

                    res.data.reject = params.state.oldData.reject
                    setData(res.data)
                } else {
                    setData(res.data)
                    res.data.data2 = params.state.oldData.data2
                }



            }

        })


    }, [selector]);

    const getRP = (months) => {
        if (months.includes('to')) {
            let startDate = moment(months.split('to')[0].trim())
            let endDate = moment(months.split('to')[1].trim())
            let rp = []
            while (startDate <= endDate) {

                rp.push(startDate.format('MM-YYYY'));
                startDate.add(1, 'month');


            }
            return rp
        } else {
            return [moment(months).format('MM-YYYY')]
        }
    }
    const checkHardcoded = () => {
        console.log(id)
        if (hardcoded.rf.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const migrateResponse = (formData, oldData) => {

        formData.forEach((i) => {
            let index = oldData.findIndex((j) => { return getType(j.type) === i.type && i.name === j.name })
            if (index !== -1) {
                if (i.type !== 'checkbox-group' && i.type !== 'radio-group') {

                    i.value = oldData[index].value
                } else {

                    i.values.forEach((k, l) => {
                        k.selected = oldData[index].value.includes(k.label) || oldData[index].value.includes(l)
                    })
                }
            }
        })

        return formData
    }
    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.label === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar className="col-5 fs-14 fw-4" value={item.value !== null ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText keyfilter="num" onWheel={(e) => e.target.blur()} type='number' style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, parseFloat(e.target.value), 'as') }} />
                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton inputId={"cb" + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"cb" + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>


                </div>
            )
        } else if (item.type === 'select') {
            item.value = item.values.filter((i) => { return i.selected }).length === 0 ? null : item.values.filter((i) => { return i.selected })[0].label
            console.log(item.value)
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.target.value) }} />
                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5">
                        <Editor className="text-area" value={item.value} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => onNumberChange(item, e.htmlValue)} />

                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >
                        <div style={{
                            background: '#f8f9fa',
                            border: '1px solid #ced4da',
                            borderRadius: '6px 6px 0px 0px',
                            padding: '8px'
                        }}>
                            <label htmlFor={'fp' + index} className="fs-14 clr-navy" style={{
                                marginRight: 10,
                                padding: '5px',

                                background: 'white',
                                border: '1px solid cornflowerblue',
                                borderRadius: '10px',

                            }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-folder-open clr-navy" />
                                Add Attachment
                            </label>
                            <label
                                onClick={() => { resetFiles(item, index) }}
                                style={{
                                    padding: '5px',
                                    fontSize: '15px',
                                    border: '1px solid indianred',
                                    background: 'white',
                                    borderRadius: '10px',
                                    color: 'indianred'
                                }} >
                                <i style={{ fontSize: 15, margin: 5 }} className="pi pi-undo" />
                                Reset
                            </label>
                            <input type='file' accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.pdf,.PDF" id={'fp' + index} hidden onChange={(e) => { handleFileUpload(e, item) }} ></input>
                        </div>
                        {item.value !== undefined && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()

            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }

                }
            }

            else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }


    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }

    const getType = (item) => {
        if (item === 1) {
            return 'text'
        } else if (item === 2) {
            return 'textarea'
        } else if (item === 3) {
            return 'select'
        } else if (item === 4) {
            return 'checkbox-group'
        } else if (item === 5) {
            return 'number'
        } else if (item === 6) {
            return 'radio-group'
        } else if (item === 7) {
            return 'file'
        } else if (item === 'paragraph') {
            return 'paragraph'
        } else if (item === 9) {
            return 'date'
        }
    }
    const reduceResponse = (item) => {
        if (item.type === 'text') {
            return { type: 1, value: item.value === undefined ? '' : item.value, name: item.name }
        } else if (item.type === 'textarea') {

            return { type: 2, value: item.value === undefined ? '<p></p>' : item.value, name: item.name }
        } else if (item.type === 'select') {

            return { type: 3, value: item.value === undefined ? null : item.value, name: item.name }
        } else if (item.type === 'checkbox-group') {
            return { type: 4, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'number') {
            return { type: 5, value: item.value, name: item.name }
        } else if (item.type === 'radio-group') {
            return { type: 6, value: item.values.map((k, i) => { if (k.selected === true) { return k.value } }).filter((i) => { return i !== undefined }), name: item.name }
        } else if (item.type === 'file') {
            return { type: 7, value: item.value, name: item.name }
        } else if (item.type === 'paragraph') {
            return item
        } if (item.type === 'date') {
            return { type: 9, value: item.value, name: item.name }
        }
    }
    const saveRF = () => {
        let newObj = {}
        console.log(checkResponse())
        if (checkResponse()) {
            newObj['rfid'] = parseInt(id)
            newObj['framework'] = params.state.data.tags

            newObj['response'] = data.data1.map((i) => reduceResponse(i))
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['categoryId'] = params.state.data.cat_id
            newObj['indicatorId'] = params.state.data.id
            newObj['topicId'] = params.state.data.top_id
            newObj['data2'] = ''
            newObj['coverage'] = params.state.data.coverage
            newObj['level'] = params.state.data.level
            newObj['entity_type'] = params.state.data.type

            newObj['reject'] = data.reject

            newObj['submitted_by'] = selector.id

            newObj['created_on'] = moment.utc()

            APIServices.post(API.RF_Submit_UP(client_info.id), newObj).then((res) => {
                Swal.fire({
                    title: "Changes Saved Successfully",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })

            })



        }
        forceUpdate()

    }
    const saveRFNA = () => {
        let newObj = {}

        newObj['rfid'] = parseInt(id)
        newObj['framework'] = params.state.data.tags

        newObj['response'] = []
        newObj['type'] = 0
        newObj['edit'] = 1
        newObj['categoryId'] = params.state.data.cat_id
        newObj['indicatorId'] = params.state.data.id
        newObj['topicId'] = params.state.data.top_id
        newObj['data2'] = ''
        newObj['coverage'] = params.state.data.coverage
        newObj['level'] = params.state.data.level
        newObj['entity_type'] = params.state.data.type

        newObj['reject'] = data.reject

        newObj['submitted_by'] = selector.id

        newObj['created_on'] = moment.utc()

        APIServices.post(API.RF_Submit_UP(client_info.id), newObj).then((res) => {
            Swal.fire({
                title: "Submitted as NA",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    navigate.goBack()
                }
            })
        }).catch((e) => {
            Swal.fire({
                title: "Something went wrong, try after some time. Contact admin if issue still persist",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    navigate.goBack()
                }
            })

        })




        forceUpdate()

    }
    const saveRF__ = () => {
        let newObj = {}
        if (data.data1.length !== 0) {
            newObj['rfid'] = parseInt(id)
            newObj['framework'] = params.state.data.tags
            newObj['response'] = data.data1
            newObj['type'] = 0
            newObj['edit'] = 0
            newObj['categoryId'] = params.state.data.cat_id
            newObj['indicatorId'] = params.state.data.id
            newObj['topicId'] = params.state.data.top_id
            newObj['data2'] = ''
            newObj['coverage'] = params.state.data.coverage
            newObj['level'] = params.state.data.level
            newObj['entity_type'] = params.state.data.type
            newObj['reject'] = data.reject

            newObj['submitted_by'] = selector.id

            newObj['created_on'] = moment.utc()

            APIServices.post(API.RF_Submit_UP(client_info.id), newObj).then((res) => {
                Swal.fire({
                    title: "Changes Saved Successfully",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })

            })



        } else {
            Swal.fire({
                title: "Cannot submit as empty",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                Swal.close()
            })


        }
        forceUpdate()

    }
    const saveRFNA__ = () => {
        let newObj = {}

        newObj['rfid'] = parseInt(id)
        newObj['framework'] = params.state.data.tags
        newObj['response'] = []
        newObj['type'] = 0
        newObj['edit'] = 1
        newObj['categoryId'] = params.state.data.cat_id
        newObj['indicatorId'] = params.state.data.id
        newObj['topicId'] = params.state.data.top_id
        newObj['data2'] = ''
        newObj['coverage'] = params.state.data.coverage
        newObj['level'] = params.state.data.level
        newObj['entity_type'] = params.state.data.type
        newObj['reject'] = data.reject

        newObj['submitted_by'] = selector.id

        newObj['created_on'] = moment.utc()

        APIServices.post(API.RF_Submit_UP(client_info.id), newObj).then((res) => {
            Swal.fire({
                title: "Submitted as NA",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    navigate.goBack()
                }
            })
        }).catch((e) => {
            Swal.fire({
                title: "Something went wrong, try after some time. Contact admin if issue still persist",

                confirmButtonText: 'Exit',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    navigate.goBack()
                }
            })

        })




        forceUpdate()

    }
    const saveRF_ = () => {
        let newObj = {}
        console.log(data.data2)
        if (typeof data.data2 === 'string' && data.data2.trim().length !== 0) {
            newObj['rfid'] = parseInt(id)
            newObj['framework'] = [0, 1]
            newObj['response'] = []
            newObj['type'] = 1
            newObj['edit'] = 0
            newObj['categoryId'] = params.state.data.cat_id
            newObj['indicatorId'] = params.state.data.id
            newObj['topicId'] = params.state.data.top_id
            newObj['created_on'] = moment.utc()
            newObj['data2'] = data.data2
            newObj['reject'] = 0
            newObj['coverage'] = params.state.data.coverage
            newObj['level'] = params.state.data.level
            newObj['entity_type'] = params.state.data.type
            newObj['submitted_by'] = selector.id

            console.log(newObj)
            APIServices.post(API.RF_Submit_UP(client_info.id), newObj).then((res) => {
                Swal.fire({
                    title: "Changes Saved Successfully",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        navigate.goBack()
                    }
                })

            })



        }
        forceUpdate()

    }
    const getBGColor = (clr) => {

        if (data.reject === 1 && clr === data.reject) {
            return 'red'
        } else if (data.reject === 2 && clr === data.reject) {
            return 'indianred'
        } else if (data.reject === 3 && clr === data.reject) {
            return 'darkorange'
        } else if (data.reject === 4 && clr === data.reject) {
            return 'mediumseagreen'
        } else if (data.reject === 5 && clr === data.reject) {
            return 'green'
        } else {
            return 'white'
        }
    }
    const getColor = (clr) => {

        if (data.reject === 1 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 2 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 3 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 4 && clr === data.reject) {
            return 'white'
        } else if (data.reject === 5 && clr === data.reject) {
            return 'white'
        } else { return 'black' }
    }


    return (
        <div className="grid" style={{ margim: 10 }} >
            <div className="col-12">
                {(selector.id !== undefined && data.length !== 0) ?
                    <div>

                        <div className="fs-20 fw-7 clr-gray-900">
                            <h4><span className="mr-2">{'DF ' + id}</span> {data.title}</h4>
                        </div>


                        <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }}  >
                            <div className="grid col-12 ">
                                <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Aspects:&nbsp;</span> <div className="clr-gray-900 fs-16 fw-7" style={{ display: 'flex' }}>{params.state.data.title}   <Tooltip className="tag-tooltip" target={".tags"} position={'top'} autoHide={true}> {params.state.data.overallTags.map((i, j) => {
                                    if (i.length !== 0) {
                                        return (
                                            <>
                                                <label style={{ color: 'black', display: 'flex' }}> {
                                                    j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                                }
                                                </label>
                                                {
                                                    i.map((tag, k) => {

                                                        return (
                                                            <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                        )

                                                    })
                                                }
                                                <div style={{ marginBottom: 10 }} />
                                            </>
                                        )
                                    }
                                })} </Tooltip>
                                    <div style={{ alignItems: 'center' }} ><i className={"material-icons ml-2 tags"} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

                                </div>  </div>
                                <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Category:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.cat_title}  </span>  </div>

                                <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Last updated:&nbsp;</span> <span className="clr-gray-900 fw-7"> {params.state.oldData.length !== 0 ? moment(params.state.oldData.created_on).local().format('DD MMM YYYY, hh:mm A') : 'NA'}</span>  </div>
                            </div>
                            <div className="grid col-12">
                                <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Requirement:&nbsp;</span>  <span className="clr-gray-900 fw-7">{params.state.data.data1[0].title}</span> </div>
                                <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Topic:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.state.data.top_title}   </span>  </div>

                                {/* <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Version:&nbsp;</span> <span className="clr-gray-900 fw-7">  {moment(data.updated).local().format('DD MMM YYYY, hh:mm A')}</span>  </div> */}
                            </div>
                        </div>
                        <div className="flex fs-16 fw-5 bg-white" style={{ padding: 24 }}>
                            <InputSwitch checked={show} style={{ marginRight: 20 }} onChange={(e) => setShow(e.value)} />    Is this aspect relevant to  your operations ?
                        </div>
                        {show ?
                            <div >

                                {(data.data1.length !== 0 && !checkHardcoded(id)) ?
                                    <div>
                                        <div className="bg-white" style={{ padding: 24 }} >
                                            {
                                                data.data1.map((item, index) => {

                                                    return renderItems(item, index)
                                                })

                                            }
                                        </div>
                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                        <div className="bg-white grid" style={{ padding: 24 }}>

                                            <div className="col-5 fw-7 fs-16 clr-gray-900 flex align-items-center" >
                                                <Tooltip target={".selfass"} position='bottom' />
                                                Self-assessment on level of the implementation <img className="ml-2 selfass" width={20} height={20} data-pr-tooltip={'This indicates the applicability/coverage of the indicator where: Full- Full Implementation, High- Highly implemented, Moderate- Moderately implemented, Limited- Implemented to limited operations and None- Not implemented'} src={require('../../assets/images/newui/common/info.png').default} ></img>
                                            </div>
                                            <div className="col-5">
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <label onClick={() => { data.reject = 5; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                    <label onClick={() => { data.reject = 4; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                    <label onClick={() => { data.reject = 3; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                    <label onClick={() => { data.reject = 2; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                    <label onClick={() => { data.reject = 1; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>

                                                </div>


                                            </div>
                                        </div>
                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                <Button label='Discard Changes & Exit' className="mr-2" onClick={() => { navigate.goBack() }} text></Button>

                                                {/* <Button label='Submit as NA' onClick={() => { saveRFNA() }} ></Button> */}

                                                <Button label='Save Changes and Exit' onClick={() => { saveRF() }} ></Button>

                                            </div>
                                        </div>
                                    </div>
                                    : checkHardcoded(id) &&
                                    <div>
                                        <div className="bg-white" style={{ padding: 24 }}>
                                            {id === '85' ?
                                                <div>
                                                    <BGSQ14 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                </div>
                                                : id === '89' ?
                                                    <div>
                                                        <BGSQ15 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                    </div> : id === '102' ?
                                                        <div>
                                                            <BGSQ21 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                        </div> : id === '104' ?
                                                            <div>
                                                                <BP1EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '110' ? <div>
                                                                <BGSQ24 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '111' ? <div>
                                                                <BP4EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '112' ? <div>
                                                                <BP7EQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '113' ? <div>
                                                                <BP7LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '114' ? <div>
                                                                <BP8LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '115' ? <div>
                                                                <BP8LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '116' ? <div>
                                                                <BP8LQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '117' ? <div>
                                                                <BP8LQ5 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '118' ? <div>
                                                                <BP8LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '121' ? <div>
                                                                <BP7EQ1B data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '133' ? <div>
                                                                <BP8EQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '134' ? <div>
                                                                <BP9LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '139' ? <div>
                                                                <BP2LQ1 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '140' ? <div>
                                                                <BP2LQ2 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '148' ? <div>
                                                                <BP6EQ10 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '149' ? <div>
                                                                <BP6EQ11 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '150' ? <div>
                                                                <BP6EQ12 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '151' ? <div>
                                                                <BP6LQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '181' ? <div>
                                                                <SGXGSQ6 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '182' ? <div>
                                                                <SGXGSQ7 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div> : id === '183' &&
                                                            <div>
                                                                <SGXGSQ4 data={data.data1} edit={1} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div>
                                            }</div>
                                        <div className="card flex justify-content-center" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 30 }}>
                                                Self-assessment on level of the implementation
                                            </div>
                                            <div className="grid">
                                                <label onClick={() => { data.reject = 5; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(5), color: getColor(5) }}>Full</label>
                                                <label onClick={() => { data.reject = 4; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(4), color: getColor(4) }}>High</label>
                                                <label onClick={() => { data.reject = 3; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(3), color: getColor(3) }}>Moderate</label>
                                                <label onClick={() => { data.reject = 2; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(2), color: getColor(2) }}>Limited</label>
                                                <label onClick={() => { data.reject = 1; forceUpdate() }} className="col-2" style={{ justifyContent: 'center', marginLeft: '-20px', borderRadius: 20, display: 'flex', width: 110, border: '1px solid darkgray', background: getBGColor(1), color: getColor(1) }}>None</label>
                                            </div>


                                        </div>
                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ padding: 10, background: 'white' }}>
                                                <Button label='Discard Changes & Exit' onClick={() => { navigate.goBack() }}></Button>

                                                <Button label='Submit as NA' onClick={() => { saveRFNA__() }} ></Button>

                                                <Button label='Save Changes and Exit' onClick={() => { saveRF__() }} ></Button>


                                            </div>
                                        </div>
                                    </div>
                                }

                            </div> :
                            <div>
                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                <div className="grid bg-white" style={{ padding: 24 }} >
                                    <label className="col-5 fw-7 fs-16">justification</label>
                                    <InputTextarea className='col-5' style={{ padding: 10 }} autoResize={true} value={data.data2} onChange={(e) => { data.data2 = e.target.value; forceUpdate() }} />

                                </div>
                                <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 20 }}>
                                    <Button label='Discard Changes & Exit' onClick={() => { navigate.goBack() }}></Button>


                                    <Button label='Save Changes and Exit' onClick={() => { saveRF_() }} ></Button>


                                </div>
                            </div>}
                    </div>
                    :
                    <></>
                }
            </div>
        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RFInputEntrySubmission, comparisonFn);
