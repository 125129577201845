import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';

import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from "react-redux";
import './assets/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import Login from './pages/Login';
import DCFInputEntryApproval from './pages/reviewer/DCFInputEntryApproval';
import { API } from './constants/api_url';
import PasswordReset from './pages/passwordReset';
import '../src/Styles/custom.css'
import '../src/Styles/ui_ux.css'
// import '../src/Styles/sidebar.css'
import APIServices from './service/APIService';

import Sidebar from './components/sidebar/sidebar';
import '../src/components/sidebar/sidebar.scss'
import DCFInputEntryPreview from './pages/preview/DCFInputEntryPreview';
import RFInputEntryPreview from './pages/preview/RFInputEntryPreview';
import DCFInputEntrySubmission from './pages/submission/DCFInputEntrySubmission';
import DCFInputEntrySubmissionPreview from './pages/preview/DCFInputEntrySubmissionPreview';
import RFInputEntrySubmission from './pages/submission/RFInputEntrySubmission';
import SRFInputEntrySubmission from './pages/submission/SRFInputEntrySubmission';
import SRFInputEntryPreview from './pages/preview/SRFInputEntryPreview';
import SRFInputEntryApproval from './pages/reviewer/SRFInputEntryApproval';
import SRFInputEntrySubmissionPreview from './pages/preview/SRFInputEntrySubmissionPreview';
import RFInputEntrySubmissionPreview from './pages/preview/RFInputEntrySubmissionPreview';
import ReporterOverdue from './Dashboard/Reporter/ReporterOverdue';
import ReporterDueNow from './Dashboard/Reporter/ReporterDueNow';
import ApproverOverdue from './Dashboard/Approver/ApproverOverdue';
import ReporterHistoric from './Dashboard/Reporter/ReporterHistoric';
import ApproverHistoric from './Dashboard/Approver/ApproverHistoric';
import ApproverDueNow from './Dashboard/Approver/ApproverDueNow';
import QualitativeHistoric from './Dashboard/Qualitative/QualitativeHistoric';

import Approver from './Dashboard/approver';
import UserHome from './Dashboard/UserHome';
import SRFReporterOverdue from './Dashboard/SRF/SRFReporterOverdue';
import SRFReporterHistoric from './Dashboard/SRF/SRFReporterHistoric';
import SRFReviewerOverdue from './Dashboard/SRF/SRFReviewerOverdue';
import SRFReviewerHistoric from './Dashboard/SRF/SRFReviewerHistoric';
import SupplierHome from './Dashboard/SupplierHome';

import OverdueMailReport from './components/Mailing/overdueMailReport';
import UserHomeOld from './Dashboard/UserHomeOld';
import QualitativeHistoricOld from './Dashboard/Qualitative/QualitativeHistoricOld';
import UserDashboard from './Dashboard/UserDashboard';
import ReporterOverdueNew from './Dashboard/Reporter/QuantitativeReporterOverdue';
import QuantitativeNewSubmission from './pages/submission/QuantitativeNewSubmission';
import QuantitativePastSubmission from './pages/submission/QuantitativePastSubmission';
import ReporterHistoricNew from './Dashboard/Reporter/QuantitativeReporterHistoric';
import QLReviewerOverdue from './Dashboard/Reviewer/QuantitativeReviewerOverdue';
import QuantitativeReviewer from './pages/reviewer/QuantitativeReviewer';
import QLReviewerHistoric from './Dashboard/Reviewer/QuantitativeReviewerHistoric';

import QuantitativeReviewerOverdue from './Dashboard/Reviewer/QuantitativeReviewerOverdue';
import QuantitativeReviewerHistoric from './Dashboard/Reviewer/QuantitativeReviewerHistoric';
import QuantitativeReporterOverdue from './Dashboard/Reporter/QuantitativeReporterOverdue';
import QuantitativeReporterHistoric from './Dashboard/Reporter/QuantitativeReporterHistoric';
import QuantitativeApprover from './pages/approver/QuantitativeApprover';
import QuantitativeApproverOverdue from './Dashboard/Approver/QuantitativeApproverOverdue';
import QuantitativeApproverHistoric from './Dashboard/Approver/QuantitativeApproverHistoric';
import QuantitativeReviewerOverall from './Dashboard/Reviewer/QuantitativeReviewerOverall';
import LoginNew from './pages/LoginNew';
import QuantitativeReporterOverall from './Dashboard/Reporter/QuantitativeReporterOverall';
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import QuantitativeApproverDB from './Dashboard/Approver/QuantitativeApproverDB';
import overallSubmissionPreview from './pages/submission/overallSubmissionPreview';
import QualitativeReporterOverall from './Dashboard/Reporter/QualitativeReporterOverall';
import QualitativeNewSubmission from './pages/submission/QualitativeNewSubmission';
import QualitativeConsolidator from './pages/approver/QualitativeConsolidator';
import QualitativeApprover from './Dashboard/Approver/QualitativeApprover';
import { custompath } from './constants/pathManagement';
import RotaryLogin from './client/login/RotaryLogin';
import TVSLogin from './client/login/TvsLogin';
import QuantitativeReporterOverallCopy from './Dashboard/Reporter/QuantitativeReporterOverall copy';
import QuantitativeReviewerOverallCopy from './Dashboard/Reviewer/QuantitativeReviewerOverall copy';
import AdminRedirect from './AdminRedirect';
import TVSAWSLogin from './client/SSOLogin/TVSLogin';
const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()
    const config = useSelector(state => state.user.userdetail)
    const dispatch = useDispatch()
    PrimeReact.ripple = true;

    const groupedReports = [

        { label: 'GRI', value: 11, to: '/dummy_report' },
        { label: 'SASB', value: 12, to: '/dummy_report' },
        { label: 'GHG Inventory', value: 21, to: '/dummy_report' },
        { label: 'TCFD', value: 22, to: '/dummy_report' },
        { label: 'Boursa Kuwait', value: 31, to: '/dummy_report' },
        { label: 'Bursa Malaysia', value: 32, to: '/dummy_report' },
        { label: 'HKEX', value: 33, to: '/dummy_report' },
        { label: 'NASDAQ', value: 34, to: '/dummy_report' },
        { label: 'SGX', value: 35, to: '/dummy_report' },
        { label: 'SEBI (BRSR)', value: 36, to: '/brsr_report' },
        { label: 'CDP', value: 41, to: '/dummy_report' },
        { label: 'EcoVadis', value: 42, to: '/dummy_report' },
        { label: 'MSCI', value: 43, to: '/dummy_report' },
        { label: 'S&P', value: 44, to: '/dummy_report' },
        { label: 'Sustainalytics', value: 45, to: '/dummy_report' },
        { label: 'MCfS', value: 51, to: '/dummy_report' },
        { label: 'Unilever', value: 52, to: '/dummy_report' },
        { label: 'Goldman Sachs', value: 61, to: '/dummy_report' },
        { label: 'EQT', value: 62, to: '/dummy_report' },
        { label: 'ESG Report', value: 71, to: '/dummy_report' },
        { label: 'CSR Report', value: 72, to: '/dummy_report' },
        { label: 'MOEFCC', value: 81, to: '/dummy_report' }
    ];
    const [stdlist, setSTDList] = useState([])
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {

        if (localStorage.getItem('token') === null  &&  !location.pathname.match('/redirect') && !location.pathname.match('/reset-password-finish')) {
            history.push('/')
            sessionStorage.removeItem('temp')
        }
    }, [])

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
                setSTDList(res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 }))
            })
        }

        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        dispatch(resetOverallPendingData())
        dispatch(resetLoggedUserDetail());
        localStorage.removeItem('token')
        sessionStorage.clear()
        history.push('/')



    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }


    const edituser =
        [

            // {
            //     label: 'Home', icon: 'pi pi-fw pi-desktop', to: '/client_user/homescreen'
            // },
            {
                label: 'Home', icon: 'pi pi-fw pi-desktop', to: '/client_user_new/homescreen'
            }, {
                label: 'Logout', icon: 'pi pi-sign-out ', to: '/logout', exit: true
            },

        ];
    const editsupplier =
        [

            {
                label: 'Home', icon: 'pi pi-fw pi-desktop', to: '/client_supplier/homescreen'
            },


        ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {localStorage.getItem('token') &&
                <>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <Sidebar logout={onMenuItemClick} onToggleMenuClick={onToggleMenuClick} menuitems={Object.keys(config).length !== 0 && config.role === 'clientsupplier' ? editsupplier : edituser} />


                </>
            }
            <div className={localStorage.getItem('token') && "layout-main-container bg-smoke"}>
                <div className="layout-main" style={{ marginTop: 10 }}>

                    {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                    <Route exact path="/bkp" component={Login} />
                    <Route exact path="/" component={custompath.login === 1 ? RotaryLogin : custompath.login === 2 ? TVSLogin : custompath.login === 3 ? TVSAWSLogin : LoginNew} />
                    <Route exact path="/redirect" component={AdminRedirect} />
                    <Route path='/reset-password-finish' component={PasswordReset} />
                    <Route path='/overdue_reminder' component={OverdueMailReport} />
                    {localStorage.getItem('token') &&
                        <>
                            {/* LoginDashboard */}
                            <Route path='/client_user_new/homescreen' component={UserDashboard} />
                            {/* Dashboard Card path */}
                            <Route path='/quantitative_reporter_overdue_older' component={QuantitativeReporterOverall} />
                            <Route path='/quantitative_reviewer_overdue_older' component={QuantitativeReviewerOverall} />
                            <Route path='/quantitative_reporter_overdue' component={QuantitativeReporterOverallCopy} />
                            <Route path='/quantitative_reviewer_overdue' component={QuantitativeReviewerOverallCopy} />
                            <Route path='/quantitative_approver_overdue' component={QuantitativeApproverOverdue} />
                            <Route path='/quantitative_reporter_historic' component={QuantitativeReporterHistoric} />
                            <Route path='/quantitative_reviewer_historic' component={QuantitativeReviewerHistoric} />
                            <Route path='/quantitative_approver_historic' component={QuantitativeApproverHistoric} />

                            {/* QuantiativeSubmissionViews */}

                            <Route path='/data_input_reviewer/:id/:id2' component={QuantitativeReviewer} />
                            <Route path='/data_input_approver/:id/:id2' component={QuantitativeApprover} />
                            <Route path='/data_input_view/:id/:id2' component={overallSubmissionPreview} />
                            {/* QuantitativeSubmissionSubmission */}
                            <Route path='/data_input_newer/:id' component={QuantitativeNewSubmission} />
                            <Route path='/data_input_past/:id/:id2' component={QuantitativePastSubmission} />
                            {/* screentodownload report through mail */}
                            <Route path='/quantitative_reporter_overdue_old' component={QuantitativeReporterOverdue} />
                            {/* Under Development */}

                            <Route path='/approver' component={Approver} />
                            <Route path='/qualitative_reporter_overall' component={QualitativeReporterOverall} />
                            <Route path='/qualitative_approver' component={QualitativeApprover} />
                            <Route path='/quantitative_approver' component={QuantitativeApproverDB} />
                            {/* retiered */}
                            <Route path='/reporter_overdue/:id' component={ReporterOverdue} />
                            <Route path='/reporter_duenow/:id' component={ReporterDueNow} />
                            <Route path='/reviewer_duenow/:id' component={ApproverDueNow} />
                            <Route path='/reviewer_overdue/:id' component={ApproverOverdue} />
                            <Route path='/reporter_historic/:id' component={ReporterHistoric} />
                            <Route path='/reviewer_historic/:id' component={ApproverHistoric} />
                            <Route path='/qualitative_historic' component={QualitativeHistoric} />
                            <Route path='/client_user/homescreen' component={UserHome} />
                            <Route path='/client_supplier/homescreen' component={SupplierHome} />

                            {/* <Route path='/quantitative_reviewer_overdue' component={QuantitativeReviewerOverdue} /> */}
                            {/* CF Dashboard */}
                            {/* <Route path='/srf_reporter_overdue/:id' component={SRFReporterOverdue} />
                            <Route path='/srf_reporter_historic/:id' component={SRFReporterHistoric} />
                            <Route path='/srf_reviewer_overdue/:id' component={SRFReviewerOverdue} />
                            <Route path='/srf_reviewer_historic/:id' component={SRFReviewerHistoric} /> */}
                            {/* DCF */}
                            {/* <Route path='/data_input_new/:id' component={DCFInputEntrySubmission} />
                            <Route path='/data_input_approve/:id' component={DCFInputEntryApproval} />
                            <Route path='/submission_preview/:id' component={DCFInputEntrySubmissionPreview} />
                            <Route path='/dcf_preview/:id' component={DCFInputEntryPreview} /> */}

                            {/* RF */}
                            {/* <Route path='/rf_input_entry/:id' component={RFInputEntrySubmission} />
                            <Route path='/rf_input_entry_new' component={QualitativeNewSubmission} /> */}
                            {/* <Route path='/rf_input_entry/:id' component={rf_input_entry} /> */}
                            {/* 
                            <Route path='/rf_submission_preview/:id' component={RFInputEntrySubmissionPreview} />
                            <Route path='/qualitative_consolidate' component={QualitativeConsolidator} />
                            <Route path='/rf_preview/:id' component={RFInputEntryPreview} /> */}
                            {/* CF */}
                            {/* <Route path='/srf_data_input/:id' component={SRFInputEntrySubmission} />
                            <Route path='/srf_preview/:id' component={SRFInputEntryPreview} />
                            <Route path='/srf_input_approve/:id' component={SRFInputEntryApproval} />
                            <Route path='/srf_submission_preview/:id' component={SRFInputEntrySubmissionPreview} /> */}

                        </>
                    }



                    {/* {localStorage.getItem('token') !== null && <AppFooter layoutColorMode={layoutColorMode} />} */}
                </div>
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;


// "repository": {
//     "type": "git",
//     "url": "https://github.com/primefaces/sakai-react.git"
// },