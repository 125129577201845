
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/api_url";
import { isEmptyArray } from "formik";
import APIServices from "../../service/APIService";

const initialState = {

    siteList: [],
    locationList: []

}
let uriString = {
    "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

}

let site_api = (id) => { return API.LocationOne_UP(id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}` }

export const fetchSiteList = createAsyncThunk('api/sitelist', async (id) => {
    return APIServices.get(site_api(id)).then((res) => res.data)
})
const getLocationDropdown = (state_, state) => {
    let capture = []
    state_.forEach((cat, i) => {

        if (cat.locationTwos !== undefined) {


            cat.locationTwos.forEach((topic, j) => {

                if (topic.locationThrees !== undefined) {

                    topic.locationThrees.forEach((metric, k) => {




                        let capi = capture.findIndex((aa) => { return aa.id === cat.id })
                        if (capi === -1) {
                            capture.push({
                                ...cat, locationTwos: [
                                    {
                                        ...topic, locationThrees: [
                                            {
                                                ...metric


                                            }
                                        ]
                                    }
                                ]
                            })

                        } else {
                            let topi = capture[capi].locationTwos.findIndex((aa) => { return aa.id === topic.id })
                            if (topi === -1) {
                                capture[capi].locationTwos.push({

                                    ...topic, locationThrees: [
                                        {
                                            ...metric
                                        }
                                    ]

                                })
                            } else {
                                let meti = capture[capi].locationTwos[topi].locationThrees.findIndex((aa) => { return aa.id === metric.id })
                                if (meti === -1) {
                                    capture[capi].locationTwos[topi].locationThrees.push({


                                        ...metric


                                    })
                                }
                                else {

                                }

                            }
                        }




                    })
                }
            })
        }
    })
    state.siteList = capture;


}
const getLocations = (state) => {

    let location = []
    state.siteList.forEach((i) => {
        if (i.locationTwos !== undefined) {
            i.locationTwos.forEach((j) => {
                if (j.locationThrees !== undefined) {
                    j.locationThrees.forEach((k) => {
                        location.push({ title: k.name, name: k.name + '( ' + j.name + ' )', id: k.id, country: i, city: j })
                    })
                }
            })
        }
    })
    state.locationList = location
}

const clientSiteList = createSlice({
    name: 'sitelist',
    initialState,

    extraReducers: (builder) => {

        builder.addCase(fetchSiteList.fulfilled, (state, action) => { getLocationDropdown(action.payload, state); getLocations(state) })

    }



})

export default clientSiteList.reducer


