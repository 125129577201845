
import { createSlice } from "@reduxjs/toolkit";


const initialState = {

    overallPending: [],
    ppfList: []

}


const pendingData = createSlice({
    name: 'pendingdata',
    initialState,
    reducers: {
        setOverallPendingData: (state, action) => { console.log(action.payload); state.overallPending = [...state.overallPending, ...action.payload] },
        resetOverallPendingData: (state, action) => { state.overallPending = action.payload },
        setPPFListData: (state, action) => { console.log(action.payload); state.ppfList = action.payload },
        resetPPFData: (state, action) => { state.ppfList = action.payload },


    }




})

export default pendingData.reducer
export const { setOverallPendingData, resetOverallPendingData, setPPFListData, resetPPFData } = pendingData.actions



