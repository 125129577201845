import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { InputNumber } from "primereact/inputnumber";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";

import { Editor } from "primereact/editor";
import { InputSwitch } from "primereact/inputswitch";
import { Slider } from "primereact/slider";
import { BGSQ14, BGSQ15, BGSQ21, BGSQ24, BP1EQ2, BP4EQ2, BP7EQ1B, BP7EQ2, BP7LQ1, BP8EQ1, BP8LQ1, BP8LQ2, BP8LQ4, BP8LQ5, BP8LQ6, BP9LQ1, BP2LQ1, BP2LQ2, BP6EQ10, BP6EQ11, BP6EQ12, BP6LQ6, SGXGSQ7, SGXGSQ6, SGXGSQ4 } from "../../components/hardcoded/hardcodedRF";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { AttachmentComponent } from "../../components/Forms/Attachment";
import { DateTime } from "luxon";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
window.jQuery = $;
window.$ = $;

const QualitativeConsolidator = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [summary, setSummary] = useState([])
    const [params, setParams] = useState(JSON.parse(sessionStorage.getItem('dfconsol')))
    const [sitelist, setSiteList] = useState([])
    const [submission, setSubmission] = useState([])
    const navigate = useHistory()
    const forceUpdate = useForceUpdate();
    const client_info = useSelector((state) => state.userlist.admindetail)
    const userList = useSelector(state => state.userlist.userList)
    const { id } = useParams();

    const [document, setDocument] = useState(null)
    // const hardcodedrf = ['85', '89', '102', '104', '110', '111', '112', '113', '114', '115', '116', '117', '118', '121', '133', '134', '139', '140','148','149','150','151','181','182','183']
    const [show, setShow] = useState(true)
    const test = {
        "id": 1,
        "comments": null,
        "tier0_ids": [],
        "tier1_ids": [
            7
        ],
        "tier2_ids": [
            3
        ],
        "tier3_ids": [],
        "consolidator_ids": [
            11
        ],
        "created_on": "2024-03-25T07:13:49.443Z",
        "created_by": 17,
        "modified_on": "2024-06-29T06:44:08.578Z",
        "modified_by": 17,
        "type": 1,
        "userProfileId": 17,
        "dfId": 84
    }
    const { ass } = params
    useEffect(async () => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let site_url = API.LocationOne_UP(selector.clientId) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;


        const promise0 = APIServices.get(API.RF_Edit(ass.dfId))
        const promise1 = APIServices.get(API.QL_Submit_UP(client_info.id) + `?filter%5Bwhere%5D%5BdfId%5D=${test.dfId}`)
        const promise2 = APIServices.get(site_url)
        Promise.all([promise0, promise1, promise2]).then((values) => {

            const shapedSite = values[2].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            let entity_list = filterHierarchyByTierIds(shapedSite, test.tier0_ids, test.tier1_ids, test.tier2_ids, test.tier3_ids)

            let filteredResponse = values[1].data.reverse().filter(item => entity_list.some(entity => entity.level === item.level && entity.locationId === item.locationId) ).filter(i => rpText(i.reporting_period) === params.data.reporting_period )
            let entity_list_ = JSON.parse(JSON.stringify(entity_list))
            entity_list_.forEach((i)=>{
                i.data = filteredResponse.filter( x => x.level === i.level && x.locationId === i.locationId   )
            })
            setSummary(entity_list_)
            values[0].data.data1.forEach((i) => {
                if (i.type !== 'paragraph') {
                    let entity_list_ = JSON.parse(JSON.stringify(entity_list))
                    entity_list_.forEach((ent) => {
                        console.log(ent)
                        let findIndex = filteredResponse.findIndex(item => item.level === ent.level && item.locationId === ent.locationId)

                        if (findIndex !== -1) {

                            let findIndex2 = filteredResponse[findIndex].response.findIndex(x => x.name === i.name)

                            if (findIndex2 !== -1) {
                                let obj = filteredResponse[findIndex].response[findIndex2]
                                obj.last_modified_on = filteredResponse[findIndex].last_modified_on
                                ent.data = filteredResponse[findIndex].response[findIndex2]
                                console.log('find', filteredResponse[findIndex].response[findIndex2].value, i.label)
                            }
                        }
                    })
                    i.entity = entity_list_
                }
            })
            console.log(values[0].data.data1)
            setData(values[0].data)
        })

    }, [selector]);
    function rpText(dates) {
        if (dates.length === 0) return "";
      
        const startDate = DateTime.fromFormat(dates[0], "MM-yyyy");
        const endDate = DateTime.fromFormat(dates[dates.length - 1], "MM-yyyy");
      
        const startMonthYear = startDate.toFormat('MMM-yyyy');
        const endMonthYear = endDate.toFormat('MMM-yyyy');
      
        return `${startMonthYear} to ${endMonthYear}`;
      }
    function filterHierarchyByTierIds(hierarchy, tier0Ids, tier1Ids, tier2Ids, tier3Ids) {
        let results = {
            tier0Results: [],
            tier1Results: [],
            tier2Results: [],
            tier3Results: []
        };
        if (tier0Ids.length != 0) {
            results.tier0Results.push({ id: 0, title: 'Corporate', level: 0, locationId: 0 })
        }
        hierarchy.forEach(level1 => {
            if (tier1Ids.includes(level1.id)) {
                level1.level = 1
                level1.locationId = level1.id
                results.tier1Results.push(level1);
            }

            level1.locationTwos.forEach(level2 => {
                if (tier2Ids.includes(level2.id)) {
                    level2.level = 2
                    level2.locationId = level2.id
                    results.tier2Results.push(level2);
                }

                level2.locationThrees.forEach(level3 => {
                    if (tier3Ids.includes(level3.id)) {
                        level3.level = 3
                        level3.locationId = level3.id
                        results.tier3Results.push(level3);
                    }
                });
            });
        });

        return [...results.tier0Results, ...results.tier1Results, ...results.tier2Results, ...results.tier3Results];

    }
    const questionSelected = (item, index) => {
        let loc = JSON.parse(JSON.stringify(data))
        loc.data1[index].selected = item.selected ? !item.selected : true
        setData(loc);
        forceUpdate()
    }
    const entitySelected = (item, index, ent, entindex) => {
        let loc = JSON.parse(JSON.stringify(data))
        loc.data1[index].entity[entindex].selected = loc.data1[index].entity[entindex].selected ? !loc.data1[index].entity[entindex].selected : true
        setData(loc);
        forceUpdate()
    }
    const renderItems = (item, index) => {
        console.log(item)

        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled inputId={"cb" + index + cbind} name={cb.label} value={cb.value} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar disabled placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber disabled min={0} placeholder={item.placeholder} maxFractionDigits={8} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled inputId={"rg" + index + cbind} name={cb.label} value={cb.value} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown disabled placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5 " >

                        {item.value && <div dangerouslySetInnerHTML={{ __html: item.value }} style={{ width: '100%', padding: 10, maxHeight: 350, overflow: 'auto' }} />}
                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >

                        {item.value !== undefined && item.value !== null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const statusTemplate =(rowData)=>{
        let text = 'No Response'
if(rowData.data.length !== 0){
text = DateTime.fromISO(rowData.data[0].last_modified_on,{zone:'utc'}).toLocal().toFormat('dd-LLL-yyyy')
}
        return ( <>{text}</> )
    }
    const respondenceTemplate = (rowData)=>{

let text = 'NA'
if(rowData.data.length !== 0){
    let index =  userList.findIndex(i => i.id === rowData.data[0].last_modified_by )
    if(index !== -1){
text = userList[index].information.empname
    }else{
        text= 'Not Found'
    }
    }
    return ( <>{text}</> )

    }
    return (

        <div className="grid font-lato" style={{ margim: 10 }} >
            <div className="col-12">
                <div className="fs-20 fw-7 clr-gray-900">
                    <h4><span className="mr-2">{'DF ' + data.id}</span> {data.title}</h4>
                </div>
                <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }}  >
                    <div className="grid col-12 ">
                        <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Aspects:&nbsp;</span> <div className="clr-gray-900 fs-16 fw-7" style={{ display: 'flex' }}>{params.data.title}   <Tooltip className="tag-tooltip" target={".tags"} position={'top'} autoHide={true}> {params.data.df.overallTags.map((i, j) => {
                            if (i.length !== 0) {
                                return (
                                    <>
                                        <label style={{ color: 'black', display: 'flex' }}> {
                                            j === 0 ? 'Must Have' : j === 1 ? 'Progressive' : 'Advanced'

                                        }
                                        </label>
                                        {
                                            i.map((tag, k) => {

                                                return (
                                                    <label style={{ color: 'green' }}>{tag}{k !== i.length - 1 && ','}</label>
                                                )

                                            })
                                        }
                                        <div style={{ marginBottom: 10 }} />
                                    </>
                                )
                            }
                        })} </Tooltip>
                            <div style={{ alignItems: 'center' }} ><i className={"material-icons ml-2 tags"} style={{ fontSize: 14, cursor: 'pointer' }}>info</i>  </div>

                        </div>  </div>
                        <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Category:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.data.df.cat_title}  </span>  </div>

                        <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Reporting Period :&nbsp;</span>  <span className="clr-gray-900 fw-7">{params.data.reporting_period}</span> </div>
                    </div>
                    <div className="grid col-12">
                        <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Requirement:&nbsp;</span>  <span className="clr-gray-900 fw-7">{params.data.df.title}</span> </div>
                        <div className="flex fs-16 col-4" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Topic:&nbsp;</span> <span className="clr-gray-900 fw-7">{params.data.df.top_title}   </span>  </div>


                    </div>
                    <div className="grid col-12">
                        <div className="flex fs-16 col-4" >          <span className="clr-gray-3 fw-4">Summary :&nbsp;</span>  </div>

                        <DataTable  className="auto-data-table-height fw-4 fs-16"      
                              
                                emptyMessage="No entity found."
                                scrollable value={summary}>
                            <Column header="Entity" field='name' />
                            <Column header="Status" body={statusTemplate} />
                            <Column header="Last Respondence " body={respondenceTemplate} />
                        </DataTable>


                    </div>
                </div>
            </div>
            <div className="col-12">
                {data.data1 && data.data1.length !== 0 &&
                    <div className="bg-white" style={{ padding: 24 }} >
                        {data.data1.map((item, index) => {
                            if (item.type !== 'paragraph') {
                                return (
                                    <div>
                                        <div className="m-3 br-5" style={{ background: '#12344B50' }} onClick={() => { questionSelected(item, index) }}>
                                            <div className="grid p-2 align-items-center" >
                                                <div className="col-11">

                                                    Q{index + 1}. {item.label.replace(/(<([^>]+)>)/gi, "")
                                                        .replace(/\n/g, " ")
                                                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                                                </div>
                                                <div className="col-1 flex justify-content-center">
                                                    {item.selected ?
                                                        <i className="material-icons">arrow_drop_up</i>
                                                        :
                                                        <i className="material-icons">arrow_drop_down</i>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {item.entity && item.selected &&
                                            <div className="m-2">
                                                {item.entity.map((ent, entindex) => {
                                                    return (
                                                        <div>
                                                            <div className="m-3 br-5" style={{ background: '#12344B50' }} onClick={() => { entitySelected(item, index, ent, entindex) }}>
                                                                <div className="grid p-2 align-items-center" >
                                                                    <div className="col-2">
                                                                        {ent.name}
                                                                    </div>
                                                                    <div className="col-6">
                                                                    </div>
                                                                    <div className="col-3 text-bold">
                                                                        {ent.data && 'Last Updated :' + DateTime.fromISO(ent.data.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd/LLL/yyyy')}
                                                                    </div>

                                                                    <div className="col-1 flex justify-content-center">
                                                                        {ent.selected ?
                                                                            <i className="material-icons">arrow_drop_up</i>
                                                                            :
                                                                            <i className="material-icons">arrow_drop_down</i>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {ent.selected &&


                                                                <div >
                                                                    {ent.data ?
                                                                        <div id='x'>
                                                                            {renderItems(ent.data)}
                                                                        </div> :
                                                                        <div id='y'>
                                                                            {renderItems(item)}
                                                                        </div>


                                                                    }
                                                                </div>

                                                            }
                                                        </div>
                                                    )
                                                })}

                                            </div>

                                        }
                                    </div>
                                )
                            }
                        })

                        }
                    </div>


                }
            </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QualitativeConsolidator, comparisonFn);