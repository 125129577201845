import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import App from './STTApp';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { Provider } from 'react-redux';
import store from './RTK/store';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react'
import Interceptor from '../src/service/setupInterceptor'
import { BrowserRouter } from 'react-router-dom';

let persistor = persistStore(store)

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App></App>
                </PersistGate>
            </Provider>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('root')
);
Interceptor(store)
