import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchUserList } from "./RTK/Background/userProfileList";
import { fetchSiteList } from "./RTK/Background/siteList";
import { fetchApi, resetLoggedUserDetail, resetLoggedUserDetailRedirect } from "./RTK/Login/userProfile";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { resetOverallPendingData, resetPPFData } from "./RTK/Background/pendingData";


const AdminRedirect = (props) => {

    const [load, setLoading] = useState(true)
    const select = useSelector((state) => state.user.userdetail);
    const history = useHistory()
    const dispatch = useDispatch()
    const SECRET_KEY = "e!sq6kee4token";
    const crypto = require("crypto-js");

 const loc = useLocation()
    useEffect(() => {
   
        if (localStorage.getItem('token')) {

            Swal.fire({
                title: "You have already logged in as User in other tab, ensure logout properly & close all user portal tab ",
                confirmButtonText: 'Exit Page',
                allowOutsideClick: false,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                window.close()
                }
            })
        } else if (loc.search && loc.search.slice(1)) {


    let token = decryptNumber(loc.search.slice(1))
    console.log(token)
            localStorage.setItem('token', token)
             dispatch(fetchApi(token));

        }

    }, [])
    useEffect(() => {
        if (Object.keys(select).length !== 0) {
            if (select.role === "clientuser" || select.role === "clientsupplier") {

                if (select.information.blocked === undefined || !select.information.blocked) {
                    dispatch(fetchUserList(select.clientId));
                    dispatch(fetchSiteList(select.clientId))
              
         
                        if (select.role === "clientsupplier") {
                            // props.history.push("/client_supplier/homescreen");
                // history.push('client_supplier/homescreen')
window.location.href = '/client_supplier/homescreen'
                
                            
                        } else {
                            // props.history.push("client_user_new/homescreen");
                // history.push('client_user_new/homescreen')
                window.location.href = '/client_user_new/homescreen'

                     
                        }
                    

                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Blocked",
                        text: "You have been blocked from platform, contact admin for futher details",
                        returnInputValueOnDeny: () => {
                            console.log("deny");
                        },
                    });
                    // localStorage.clear();
                    // dispatch(resetLoggedUserDetail());
                }
            }else if(select.role === "clientadmin" ){
                dispatch(fetchUserList(select.id));
                dispatch(fetchSiteList(select.id))
                          window.location.href = '/client_user_new/homescreen'
                // history.push('client_user_new/homescreen')
                // props.history.push("client_user_new/homescreen");
              
            }
        }
    }, [select]);
    function decryptNumber(encrypted) {
        var bytes = crypto.AES.decrypt(encrypted, SECRET_KEY);
        var originalText = bytes.toString(crypto.enc.Utf8);
        return originalText
    }
    return (
        <div className="font-lato">
            {load ?
            <div className="col-12 flex align-items-center justify-content-center">
                        <div> <span class="loader"></span> </div>
                <label className="col-12 fs-16 fw-5" style={{
                    
                    display: 'flex',
                    
                }}>
                    Redirecting to User Portal, please wait...
                  

                </label>

       
                </div>
                :
                <label style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: '50px'
                }}>


                </label>

            }
        </div>
    )



}

export default AdminRedirect