import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
const { DateTime } = require('luxon')


const QuantitativeReporterOverDue = () => {
    const [load, setLoading] = useState(true)
    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data  = useSelector((state) => state.user.admindetail)
    const navigate = useHistory()
    const [submittedQuantitative, setSubmittedQuantitative] = useState([])
    const [rawsitelist, setRawSitelist] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [future, setFuture] = useState([])
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }


        const promise0 = APIServices.get(API.DCF)
        const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id))
        const promise2 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.SRF_Entity_UP(admin_data.id))
        const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id))
        const promise8 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id))

        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then((values) => {
            let quantitative_submitted = values[1].data, dcf_list = values[0].data, quantitative_ass_list = values[6].data, qualitative_ass_list = values[7].data, srf_ass_list = values[8].data
            let quantitative_entity_list = values[3].data, qualitative_entity_list = values[4].data, srf_entity_list = values[5].data
            const shapedSite = values[2].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            setRawSitelist(shapedSite)
            let filtered_qn_ass = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && checkEnity(i, quantitative_entity_list, shapedSite, 'dcfId'))

            // Quantitative
            filtered_qn_ass.forEach((item) => {
                if (item.reporter_ids.includes(login_data.id)) {
                    item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                    item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]
                    let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                    if (typeof item.end_date === 'string') {
                        ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                    }
                    console.log(item)
                    getQuantitativeSubmissionData(st_date, ed_date, item.frequency, item, quantitative_submitted)
                    setLoading(false)
                }
            })
        })

    }, [])

    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex(k => k[obj] === rowData[obj])
        if (index !== -1) {
            let entity = entity_list[index]
            console.log(entity)
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0)
            } else if (rowData.level === 1) {
                return entity.tier1_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 2) {
                return entity.tier2_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 3) {
                return entity.tier3_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            }
        } else {
            return false
        }

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            console.log(country_index)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const checkSubmission = (dcf, level, locationId, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && (i.type !== 0)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
                })
            }
        }

        return result
    }
    const getQuantitativeSubmissionData = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))

        // future_ = JSON.parse(JSON.stringify(future))

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {

                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                } else {
                    if (check.data) {

                        future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })
                    }

                }
            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, type: 6, company_id: admin_data.id, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year)
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)


                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                            if (check.result) {


                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                                // } else {
                                //     let check = checkSubmission(item.dcf_, item.level,item.locationId, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                //     if (check.result) {


                                //         past_.push({ dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true: false,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                //     } else {
                                //         if (check.data) {
                                //             past_.push({ dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true: false,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency })

                                //         }

                                //     }
                                // }
                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {
                            past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, self: item.reviewer_ids.length === 0 ? true : false, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    }

                })
            }

        }
        console.log(past_)

        setPast((prev) => ([...prev, ...past_]))

        return { type, months: betweenMonths }
    }
    const titleTemplate = (rowData) => {

        return (<>
            <div className="text-underline clr-navy fw-5" onClick={() => { rowData.draft === undefined ? navigate.push({ pathname: '/data_input_newer/' + rowData.dcf.id, state: rowData }) : navigate.push({ pathname: '/data_input_past/' + rowData.dcf.id + '/' + rowData.draft.id, state: rowData }) }}>{rowData.dcf.title}</div>
        </>)
    }
    const coverageTemplate = (rowData) => {
        return (<>
            {getCoverageText(rowData,rawsitelist)}
        </>)
    }
    const sortRP = (e) => {
        console.log(e.data)
        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const sortOD = (e) => {

        if (e.order === -1) {
            return e.data.sort((a, b) => {
                const dateA = DateTime.fromFormat(a.duedate, 'MMM-yyyy');
                const dateB = DateTime.fromFormat(b.duedate, 'MMM-yyyy');
                console.log(dateA, dateB)
                // Compare the parsed dates

                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                const dateA = DateTime.fromFormat(a.duedate, 'MMM-yyyy');
                const dateB = DateTime.fromFormat(b.duedate, 'MMM-yyyy');
                console.log(dateA, dateB)
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const statusTemplate = (rowData) => {

        let type = 0
        let text = 'NOT SUBMITTED'
        let classtext = "status-tag-gray"
        if (rowData.draft !== undefined) {
            if (rowData.draft.type === 0) {
                if (rowData.draft.reject === 1) {
                    type = 1
                    text = 'RETURNED'
                    classtext = "status-tag-red"
                } else {
                    type = 2
                    text = 'DRAFT'
                    classtext = "status-tag-orange"
                }
            } else if (rowData.draft.type === 1) {
                type = 3

                text = 'SUBMITTED'
                classtext = "status-tag-green"
            } else if (rowData.draft.type === 2) {
                type = 3
                if (rowData.draft.self) {
                    text = 'Self Reviewed'
                } else {
                    text = 'Reviewed'
                }

                classtext = "status-tag-green"
            } else if (rowData.draft.type === 3) {
                type = 4
                text = 'APPROVED'
                classtext = "status-tag-green"
            }
        }
        return (
            <Tag style={{ padding: '4px 8px' }} className={classtext}>{text}</Tag>
        )
    }
    const dueTemplate = (rowData) => {
        return <spna>{rowData.duedate}</spna>
    }
    const getStatusText = (val, rowData) => {
        let text = 'NOT SUBMITTED'
        console.log(rowData)
        if (rowData.draft !== undefined) {
            if (rowData.draft.type === 0) {
                if (rowData.draft.reject === 1) {

                    text = 'RETURNED'

                } else {

                    text = 'DRAFT'

                }
            } else if (rowData.draft.type === 1) {

                text = 'SUBMITTED'

            } else if (rowData.draft.type === 2) {

                if (rowData.draft.self) {
                    text = 'Self Reviewed'
                } else {
                    text = 'Reviewed'
                }


            } else if (rowData.draft.type === 3) {

                text = 'APPROVED'

            }
        }
        return text
    }
    const sortStatus = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {
                const dateA = getStatusText('', a)
                const dateB = getStatusText('', b)
                console.log(dateA, dateB)
                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                const dateA = getStatusText('', a)
                const dateB = getStatusText('', b)

                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }
    }
    const rpFrequencyTemplate = (rowData) => {

        return <spna>{getFrequencyText(rowData.frequency)}</spna>
    }
    const getFrequencyText = (id) => {

        return frequency_list.find(i => { return i.id === id }).name
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >
                <div>
                    <div className="col-12 p-5" style={{
                        justifyContent: 'center'
                    }}>
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start"> Required Submissions ({past.length})  </label>

                    </div>
                    {
                        <DataTable loading={load} value={past} scrollable paginator rows={20} >
                            <Column header='Description' body={titleTemplate}  ></Column>
                            <Column header='Coverage' body={coverageTemplate}  ></Column>
                            <Column field="frequency" header='Reporting Frequency' body={rpFrequencyTemplate} />
                            <Column sortable sortFunction={sortRP} header='Reporting Period' field="reporting_period" />
                            <Column sortable sortFunction={sortOD} field="duedate" header='Due Month' body={dueTemplate} />
                            <Column sortable sortFunction={sortStatus} field="xyz" header='Status' body={statusTemplate} />
                            <Column header='Overdue Days' field="overdue" />
                        </DataTable>

                    }
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeReporterOverDue, comparisonFn);